import * as React from 'react'
import injectSheet from 'react-jss'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Page from '../components/Page/Page'
import HQTheme from '../theme/theme'

import classNames from 'classnames'

interface PrivacyPolicyProps {
  classes?: any
  location?: any
  data: HQ.PrivacyPolicy
}

class PrivacyPolicyPage extends React.Component<PrivacyPolicyProps, {}> {
  render() {
    const {
      data: {
        datoCmsPrivacyPolicy: { title, subtitle, description, content },
      },
      classes: c,
      location,
    } = this.props
    return (
      <Layout location={location}>
        <Page title={title} subtitle={subtitle}>
          <div className={c.contentBlocks}>
            <div className={classNames(c.contentBlock, '-alternativeBg')}>
              <div className={c.contentBlockText}>
                <div>
                  <p>{description}</p>
                </div>
              </div>
            </div>
            {content.map(({ id, title, textNode: { childMarkdownRemark: { html } } }, index) => (
              <div className={c.contentBlock} key={id}>
                <h3 className={c.contentBlockTitle}>{`${index}. ${title}`}</h3>
                <div className={c.contentBlockText} dangerouslySetInnerHTML={{ __html: html }}></div>
              </div>
            ))}
          </div>
        </Page>
      </Layout>
    )
  }
}

export const query = graphql`
  query PrivacyPolicy {
    datoCmsPrivacyPolicy {
      subtitle
      title
      description
      content {
        title
        textNode {
          childMarkdownRemark {
            html
          }
        }
        id
      }
    }
  }
`

const styles = {
  contentBlocks: {
    marginTop: '48px',
    marginBottom: '48px',
    '& > div:first-of-type': {
      marginBottom: '32px',
    },
  },
  contentBlock: {
    backgroundColor: '#FFFFFF',
    padding: '24px',
    marginBottom: '16px',
    boxShadow: '0 0px 2px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.24)',
    borderRadius: '4px',
    '& > :first-child': {
      marginTop: '0',
    },
    '& > :last-child': {
      marginBottom: '0',
    },
    '&.-alternativeBg': {
      backgroundColor: '#F2F2F2',
      boxShadow: 'none',
      borderLeft: `4px solid ${HQTheme.palette.primary.main}`,
    },
    '&.-noBg': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    '&.-compact': {
      width: '60%',
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'justify',
    },
    '&.-stretch': {
      height: 'calc(100% - 64px)',
    },
  },
  contentBlockTitle: {
    fontSize: '1.2rem',
    color: HQTheme.palette.primary.main,
    fontWeight: '500',
  },
  contentBlockText: {
    fontSize: '1rem',
    color: '#999999',
    fontWeight: '300',
    lineHeight: '1.5',

    '& a': {
      margin: '0',
      color: HQTheme.palette.text.primaryContrast,
      fontWeight: '300',
    },
    '& > div': {
      margin: '32px 0 32px 0',
    },
    '& > ul': {
      margin: '0',
    },
    '& :last-child': {
      margin: '0',
    },
  },
}

export default injectSheet(styles)(PrivacyPolicyPage)
